import enquire from "enquire.js";

const $hamburger = document.querySelector(".hamburger--js");
const $mainmenu = document.querySelector(".mainmenu--js");
const $closeBtn = document.querySelector(".mainmenu--close");

function showMenu() {
	$mainmenu.classList.add("is-active");
	$hamburger.classList.add("is-active");
}

function hideMenu() {
	$mainmenu.classList.remove("is-active");
	$hamburger.classList.remove("is-active");
}

//dop Menu
const $submenu = document.querySelector(".header__bottom-drop");
const $btn = document.querySelector(".header__bottom-menu ");

function showMenuDop() {
	$submenu.classList.add("is-active");
	$btn.classList.add("is-active");
}

function hideMenuDop() {
	$submenu.classList.remove("is-active");
	$btn.classList.remove("is-active");
}

window.onscroll = function () {
	hideMenu();
};

const clickHandler = (event) => {
	event.preventDefault();

	if ($mainmenu.classList.contains("is-active")) {
		hideMenu();
	} else {
		showMenu();
	}

	if ($submenu.classList.contains("is-active")) {
		hideMenuDop();
	}
};

enquire.register("screen and (max-width: 1024px)", {
	// deferSetup: true,
	match() {
		$hamburger.addEventListener("click", clickHandler);
		document.addEventListener("click", (event) => {
			if (event.target.dataset.backdrop === "overlay") {
				hideMenu();
			}
		});
		//$closeBtn.addEventListener('click', hideMenu)
	},
	unmatch() {
		$hamburger.removeEventListener("click", clickHandler);
		//$closeBtn.removeEventListener('click', hideMenu)
	},
});

function headerDrop() {
	$(".header__bottom-menu").on("click", function () {
		if ($submenu.classList.contains("is-active")) {
			hideMenuDop();
		} else {
			showMenuDop();
		}

		if ($mainmenu.classList.contains("is-active")) {
			hideMenu();
		}

		$(".header__bottom-drop a").on("click", function () {
			hideMenuDop();
		});
	});
}

headerDrop();
