const swiperCasino = new Swiper(".js-horScroll-how-to-buy", {
	slidesPerView: 2,
	spaceBetween: 24,
	navigation: {
		nextEl: ".how-to-buy__btn.swiper-button-next",
		prevEl: ".how-to-buy__btn.swiper-button-prev",
	},
    pagination: {
        el: ".swiper-pagination-how",
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + (index + 1) + "</span>";
        },
      },
	breakpoints: {
		319: {
			slidesPerView: 1,
		},
		1024: {
			slidesPerView: 2,
			spaceBetween: 24,
		},
	},
});