function paginationTable() {
    $('#ethereum').each(function() {
        var currentPage = 0;
        var numPerPage = 5;
        var $table = $(this);

        $table.bind('repaginate', function() {
            $table.find('tbody tr').hide().slice(currentPage * numPerPage, (currentPage + 1) * numPerPage).show();
        });
        $table.trigger('repaginate');

        var numRows = $table.find('tbody tr').length;
        var numPages = Math.ceil(numRows / numPerPage);
        var $pager = $('.ethereum-markets .swiper-pagination');


        if(numRows <= numPerPage) {
            $('#ethereum-markets .pagination').hide()
        }

        // Previous button
        $('.ethereum-markets .swiper-button-prev').bind('click', function() {
            if (currentPage > 0) {
                currentPage--;
                $table.trigger('repaginate');
                updateActivePage($pager);
            }
        });

        // Next button
        $('.ethereum-markets .swiper-button-next').bind('click', function() {
            if (currentPage < numPages - 1) {
                currentPage++;
                $table.trigger('repaginate');
                updateActivePage($pager);
            }
        });

        for (var page = 0; page < numPages; page++) {
            $('<span class="page-number"></span>').text(page + 1).bind('click', {
                newPage: page
            }, function(event) {
                currentPage = event.data['newPage'];
                $table.trigger('repaginate');
                $(this).addClass('active').siblings().removeClass('active');
            }).appendTo($pager).addClass('clickable');
        }

        $pager.find('span.page-number:first').addClass('active');

        function updateActivePage($pager) {
            $pager.find('span.page-number').removeClass('active');
            $pager.find('span.page-number').eq(currentPage).addClass('active');
        }
    });
}

paginationTable()